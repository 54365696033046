import { isAndroid, isIOS } from 'react-device-detect';

const openAndroidLink = () => {
  const url = 'https://event-dev.ava.me/download';

  // Open in external browser (Chrome) by using window.open
  window.open(url, '_blank', 'noopener,noreferrer');

  // Optional fallback to Android intent if window.open fails (for WebView cases)
  /*
  setTimeout(() => {
    window.location.replace('intent://event.ava.me/download#Intent;scheme=https;package=me.ava.android;end;');
  }, 1000);
  */
};

const openIosLink = () => {
  // TODO: Replace with iOS URL
  window.location.replace('https://event.ava.me/download');

  // Redirect to app store if app was not opened after 2s
  setTimeout(() => {
    // TODO: Replace with the iOS app store URL
    window.location.replace('https://event.ava.me/download');
  }, 2000);
};

const openWebLink = () => {
  window.location.replace('https://web.ava.me');
};

// If user is in mobile device and has the app installed, we try to open the app
// directly. Otherwise, they should be redirected to the app store to download
// the app. Desktop users will be redirected to the webapp.
export const openDeviceLink = () => {
  if (isAndroid) {
    openAndroidLink();
  } else if (isIOS) {
    openIosLink();
  } else {
    openWebLink();
  }
};
